//import { useHistory } from "react-router-dom";
//import { useTranslation } from "react-i18next";
import * as classes from './BuyDetails.module.css';

const BuyDetails = (props) => {
    /*
        const { t } = useTranslation();
    
        const history = useHistory();
        */

    return (
        <div className={classes.BuyDetailsContainer}>
            <div className={`${classes.title} font-600`}>Tilaa Hevosviihde tästä</div>
            <div className={`${classes.text} font-400`}>
                Hevosviihteen katsomiseksi tarvitset sekä Hevosviihde TotoTV tilauksen sekä Hevosviihde boxin, jonka liität televisioosi.
            </div>

            <div className={`${classes.text} font-400`}>
                Tarjoamme palvelua niin yrityksille kuin yksityisasiakkaille.
            </div>
            <div className={`${classes.text} font-400`}>
                Valitse alta haluamasi tuote
            </div>
        </div >
    );
}

export default BuyDetails;