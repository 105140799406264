import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import NavBar from "./components/NavBars/NavBar";
import CategoryContentSimilar from "./components/ViewAssets/Sliders/CategoryContentSimilar";
import DetailsVideo from "./components/Details/DetailsVideo";
//import DetailsSerie from './components/Details/DetailsSerie';
import FAQ from "./components/FAQ/FAQ";
import TOS from "./components/TOS/TOS";
import ChosenCategoryGrid from "./components/ViewAssets/Grids/ChosenCategoryGrid";
import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useMyContext } from "./contexts/StateHolder";
import BottomBar from "./components/Footers/BottomBar";
import Profile from "./components/Profile/Profile";
import settings from "./configs/config_settings.json";
import Banner from "./components/ViewAssets/BannersSlider/Banner";
//import Promo from './components/ViewAssets/Promos/Promo';
//import Search from './components/Search/Search';
import CategoriesWithTitles from "./components/ViewAssets/Sliders/CategoriesWithTitles";
import { getCategories } from "./scripts/dataHandlers";
//import LanguageSelect from './components/LanguageSelect/LanguageSelect';
//import RenderCategoryDropdown from './components/DropDowns/CategoryDropdown';
import LoginBTN from "./components/Login/LoginBTN";
import NavBarButton from "./components/NavBars/NavBarButton";
//import AssetVideoPlayer from './components/VideoPlayer/AssetVideoPlayer';
import SignUpForm from "./components/SignUp/SignUpForm";
import LoginForm from "./components/Login/LoginForm";
import { useCookies } from "react-cookie";
import ContactUs from "./components/ContactUs/ContactUs";
import "./Shared/FontAwesomeIcon";
import PurchaseFlow from "./components/Packages/PurchaseFlow";
import LoggedInBox from "./components/Packages/LoggedInBox/LoggedInBox";
import "react-multi-carousel/lib/styles.css";
//import ListAllSeries from './components/ViewAssets/Sliders/Series/ListAllSeries';
//import UpcomingEvent from './components/Events/Event/UpcomingEvent';
//import UpcomingEvents from './components/Events/EventsCategory/UpcomingEvents';
//import LiveNowEvents from './components/Events/EventsCategory/LiveNowEvents';
//import EventsEditorChoice from './components/Events/EventsEditorChoice/EventsEditorChoice';
//import EditorChoice from './components/EditorChoice/EditorChoice';
//import EventsCategory from './components/Events/EventsCategory/EventsCategory';
//import BannerSingleAsset from './components/ViewAssets/BannersSingleAsset/BannerSingleAsset';
import * as classes from "./components/NavBars/NavBar.module.css";
//import UpcomingEvent from './components/Events/Event/UpcomingEvent';
//import DetailsSeasonsAndEpisodes from './components/Details/DetailsSeasonsAndEpisodes';
//import DetailsAssetBanner from './components/Details/DetailsAssetBanner';
//import EventsCategoryItem from './components/Events/EventsCategory/EventsCategoryItem';
import { useTranslation } from "react-i18next";
import SignUpBar2 from "./components/SignUp/SignUpBar2";

// Google Analytics
import ReactGA from "react-ga";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import DownloadInstructions from "./components/Others/DownloadInstructions/DownloadInstructions";
import ProductDetails from "./components/Others/ProductDetails/ProductDetails";
import TotoDetails from "./components/Others/TotoDetails/TotoDetails";
import BuyToto from "./components/Others/BuyToto/BuyToto";
import BuyDetails from "./components/Others/BuyDetails/BuyDetails";
import PrivacyTerms from "./components/PrivacyTerms/PrivacyTerms";
//import EventsByCategoryId from './components/Events/EventsCategory/EventsByCategoryId';

//import EpisodesWithSerieTitles from './components/ViewAssets/Sliders/Series/EpisodesWithSerieTitles/EpisodesWithSerieTitles';

if (settings.googleAnalytics !== "") {
    // Initialize Google analytics with API-key from .env file // TODO
    ReactGA.initialize(settings.googleAnalytics);
}

function App() {
    // Bring stateholders from context
    const {
        setAllCategories,

        key,
        setKey,

        organizationId,
        setOrganizationId,

        language,
        setLanguage,

        setBaseRoutes,

        user,
        setUser,
    } = useMyContext();

    const [cookies] = useCookies("");

    const { t } = useTranslation();
    const location = useLocation();

    // UseEffect that will re-trigger on every pagechange, sending current location to Google Analytics
    useEffect(() => {
        if (settings.organization.googleAnalytics !== "") {
            // Report Google Analytics about user entering this page
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, [location]);

    /**** STEP 1, FIRST TIME INIT PROGRAM ****/
    useEffect(() => {
        async function initProgram() {
            try {
                // TODO: Import from suite, now importing just from config.json

                // Set organizationId to context
                setOrganizationId(settings.organization.organizationId);

                // Set key to context
                setKey(settings.organization.key);

                setBaseRoutes(settings.routes);

                console.log("app.js cookies: ", cookies);
                // If there's existing userToken in cookies
                if (cookies?.userData?.userToken) {
                    // Get user from context and copy it to modifiedUser
                    let modifiedUser = { ...user };

                    // Change status of user as logged in
                    modifiedUser.loggedIn = true;

                    // Set userToken from cookies
                    modifiedUser.userId = cookies?.userData?.userId;

                    // Set userToken from cookies
                    modifiedUser.userToken = cookies?.userData?.userToken;

                    // Update user in context, with modified data
                    setUser(modifiedUser);
                }

                // Set language from config and set it to context
                setLanguage(settings.language);
            } catch (err) {
                console.log(err);
            }
        }

        initProgram();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**** STEP 2, CHECK LANGUAGE AND GET CATEGORIES (STEP WILL BE REPEATED IF LANGUAGE CHANGES) ****/
    useEffect(() => {
        async function getAndSetCategories() {
            try {
                // Get categories from datahandler
                const categoryList = await getCategories(organizationId, key, language, user);

                // Set categories in context
                setAllCategories(categoryList);
            } catch (err) {
                console.log(err);
            }
        }

        // Get categories only if language is set
        if (language !== "") {
            getAndSetCategories();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    return (
        <div className="App">
            <div className="container">
                <Switch>
                    {/* ROUTE FOR PLAY VIDEO*/}
                    {/*
          <Route path={`/${settings.routes.playVideo}/:orgId/:asset`}>
            <AssetVideoPlayer backRoute={false} playerAutoStart={true} />
          </Route>

           */}

                    <Route>
                        <NavBar
                            links={
                                <>
                                    <div className={classes.navBarRow_primary}>
                                        <NavBarButton route={settings.routes.buy} name={t("navBar.buy")} />

                                        <NavBarButton route={settings.routes.device} name={t("navBar.device")} />

                                        <NavBarButton route={settings.routes.instructions} name={t("navBar.instructions")} />

                                        <NavBarButton route={settings.routes.faq} name={t("navBar.faq")} />

                                        <NavBarButton route={settings.routes.contactUs} name={t("navBar.contactUs")} />
                                    </div>

                                    <div className={classes.navRight}> {<LoginBTN routes={settings.routes} />}</div>
                                </>
                            }
                        />

                        {/* ROUTE FOR FRONT PAGE */}
                        <Route path="/" exact>
                            <Banner settings={settings.components.frontPageBanner_01} showArrows={false} />

                            <div style={{ width: "90%", margin: "2% auto", display: "flex", flexGrow: "1", flexDirection: "column" }}>
                                {!user.loggedIn ? <SignUpBar2 redirectRoute={settings.routes.buy} /> : null}

                                <TotoDetails />

                                <BuyToto routes={settings.routes} />
                            </div>
                        </Route>

                        {/* ROUTE FOR PURCHASE VIEW */}
                        {/*email login for tickets should come here, but not needed with kotikino*/}
                        <Route path={`/${settings.routes.packages}/:itemType?/:itemId?`} exact>
                            {user.loggedIn ? (
                                <LoggedInBox user={user} />
                            ) : (
                                <LoginForm routes={settings.routes} redirect={false} showBgImage={false} redirectRoute={"/"} />
                            )}

                            <PurchaseFlow settings={settings.components.purchaseflow} />
                        </Route>

                        {/* ROUTE FOR INSTRUCTIONS VIEW*/}
                        <Route path={`/${settings.routes.instructions}`}>
                            <DownloadInstructions />
                        </Route>

                        {/* ROUTE FOR DEVICE VIEW*/}
                        <Route path={`/${settings.routes.device}`}>
                            <ProductDetails routes={settings.routes} />
                        </Route>

                        {/* ROUTE FOR BUY VIEW*/}
                        <Route path={`/${settings.routes.buy}`}>
                            <div style={{ display: "flex", flexDirection: "column", width: "90%", margin: "0 auto 2% auto" }}>
                                <BuyDetails />
                                <BuyToto routes={settings.routes} />
                            </div>
                            <div style={{ display: "flex", flexGrow: "1" }} />
                        </Route>

                        {/* ROUTE FOR FAQ VIEW*/}
                        <Route path={`/${settings.routes.faq}`}>
                            <FAQ buyRoute={settings.routes.buy} />
                        </Route>

                        {/* ROUTE FOR TOS VIEW*/}
                        <Route path={`/${settings.routes.tos}`}>
                            <TOS />
                        </Route>

                        {/* ROUTE FOR PRIVACY TERMS VIEW*/}
                        <Route path={`/${settings.routes.privacyTerms}`}>
                            <PrivacyTerms />
                        </Route>

                        {/* ROUTE FOR PROFILE VIEW*/}

                        <Route path={`/${settings.routes.profile}`}>
                            <Profile settings={settings.components.profile} />
                        </Route>

                        {/* ROUTE FOR LOGIN VIEW*/}

                        <Route path={`/${settings.routes.login}`}>
                            <LoginForm routes={settings.routes} />
                        </Route>

                        {/* ROUTE FOR SIGNUP VIEW*/}

                        <Route path={`/${settings.routes.signUp}`}>
                            <SignUpForm routes={settings.routes} settings={settings.components.signUp} />
                        </Route>

                        {/* ROUTE FOR CONTACT US VIEW*/}
                        <Route path={`/${settings.routes.contactUs}`}>
                            <ContactUs />
                        </Route>
                        <BottomBar routes={settings.routes} />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

export default App;
