import * as classes from './PrivacyTerms.module.css';
//import { useTranslation } from 'react-i18next';

const PrivacyTerms = (props) => {
  //const { t } = useTranslation();

  return (
    <div
      className={`${classes.privacyTermsContainer} font-300`}
    >
      <div className={`${classes.privacyTermsMainTitle} font-600`}>
        {'Hevosviihde -palvelun tietosuojakäytäntö'}
      </div>
      <br />
      Voimaantulopäivä: 1. joulukuuta 2021
      <br />
      <br />
      Icareus Oy (”Icareus"), tarjoaa Hevosviihde -palvelua ("palvelu").
      <br />
      <br />
      Hevosviihde kunnioittaa ja suojelee verkkosivuillaan vierailevien henkilöiden, asiakkaidensa sekä sidosryhmätyössä toimivien henkilöiden yksityisyyttä. Tämä sivu kertoo sinulle käytännöistämme, jotka koskevat henkilötietojen keräämistä, käyttöä ja luovuttamista, kun käytät Palveluamme, ja valintoihin, jotka olet liittänyt näihin tietoihin.
      <br />
      <br />
      Käyttämällä Palvelua hyväksyt tietojen keräämisen ja käytön tämän käytännön mukaisesti. Ellei tässä tietosuojakäytännössä ole määritelty toisin, tässä tietosuojakäytännössä käytetyillä termeillä on samat merkitykset kuin palveluehdoissamme.
      <br />
      <br />
      <div className={`${classes.privacyTermsMainTitle} font-600`}>
        {'Tiedonkeruu ja käyttö'}
      </div>
      <br />
      Keräämme useita erityyppisiä tietoja eri tarkoituksiin tarjotaksemme ja parantaaksemme Palvelumme sinulle ja hallitaksemme Asiakkaan Hevosviihde -tilin käyttöä. Käytämme henkilötietoja myös viestinnässä, asiakaspalvelussa ja palveluiden toimittamisessa. Henkilötietoja käytetään myös luvallisen markkinoinnin sekä mainonnan kohdentamiseen.
      <br />
      <br />
      Henkilötietoja käsitellään keskitetysti Icareus Oy:n toimistolla Helsingissä.
      <br />
      <br />
      <div className={`${classes.privacyTermsMainTitle} font-600`}>
        {'Kerättyjen tietojen tyypit'}
      </div>
      <br />

      <div className={`${classes.privacyTermsTitle} font-400`}>Henkilökohtaiset tiedot</div>
      <br />
      Rekisteröityessäsi palveluun pyydämme sinua toimittamaan meille tiettyjä henkilökohtaisia ​​tietoja, joita voidaan käyttää yhteyden ottamiseen tai tunnistamiseen ("Henkilötiedot"). Henkilökohtaisesti tunnistettavissa oleviin tietoihin voi sisältyä muun muassa seuraavat:
      <br />
      <br />
      <div className={classes.padded}>
        <ol>
          <li>
            Sähköpostiosoite
          </li>

          <li>Etunimi ja sukunimi</li>

          <li>
            Puhelinnumero
          </li>

          <li>
            Osoite, maakunta, postinumero, kaupunki / kunta
          </li>

          <li>
            Tilaustiedot
          </li>

          <li>
            Päätelaitetiedot
          </li>

          <li>
            Keksit
          </li>

          <li>
            Käyttötiedot ja loki
          </li>
        </ol>
      </div>
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>Käyttötiedot </div>
      <br />
      Kun käytät palvelua verkkoselaimella (<a
        target='_blank'
        rel="noopener noreferrer"
        href='https://www.hevosviihde.fi'
      >
        www.hevosviihde.fi
      </a>) voimme kerätä tiettyjä tietoja automaattisesti, mukaan lukien muun muassa käyttämäsi mobiililaite, mobiililaitteesi yksilöllinen tunnus, mobiililaitteen IP-osoite, mobiilikäyttöjärjestelmäsi, käyttämäsi Internet-selaimen tyyppi, yksilölliset laitetunnisteet ja muut diagnostiikkatiedot ("Käyttötiedot").
      <br />
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>
        Seuranta- ja evästetiedot
      </div>
      <br />
      Käytämme evästeitä ja vastaavia seurantatekniikoita palvelumme toiminnan seuraamiseen ja tiettyjen tietojen säilyttämiseen.
      <br />
      <br />
      Evästeet ovat tiedostoja, joissa on pieni määrä tietoja, jotka voivat sisältää nimettömän yksilöllisen tunnisteen. Evästeet lähetetään selaimeesi verkkosivustolta ja tallennetaan laitteellesi. Eväste voi sisältää tekstiä, numeroita, päivämääriä, paikkatietoja, ja muuta dataa mutta evästeisiin ei tallennu mitään henkilökohtaisia tietoja. Eväste ei myöskään ole sovellus, eikä sen avulla voi koneeseesi päästä viruksia tai muita haittaohjelmia.  Evästeistä ei ole koneellesi minkäänlaista haittaa.
      <br />
      <br />
      Evästeiden avulla voidaan selvittää sivuston kävijämääriä, tallentaa sivustolla tehtyjä valintoja (esim. kieliasetukset), seurata kuinka sivustoa käytetään (ns. klikkauspolut) sekä kohdentaa ja hallita mainontaa (esim. ei näytetä samoja mainoksia useaan kertaan). Emme kuitenkaan seuraa yksittäisen kävijän tietoja, vaan tilastoimme kävijätietoja esim. käyttäytymisen ja maantieteellisen sijainnin perusteella.
      <br />
      <br />
      Evästeiden avulla pyrimme parantamaan käyttökokemusta sivuillamme sekä tuomaan sivuille mainontaa joka käyttäjää kiinnostaa. Voit esimerkiksi nähdä muilla sivuilla vieraillessasi Hevosviihde:n mainontaa. Tämä on mahdollista evästeiden avulla.
      <br />
      <br />
      Voit kehottaa selainta kieltäytymään kaikista evästeistä tai ilmoittamaan, kun eväste lähetetään. Jos et hyväksy evästeitä, et kuitenkaan välttämättä pysty käyttämään joitain Palvelumme osia.
      <br />
      <br />
      Esimerkkejä käyttämistä evästeistä:
      <br />
      <br />

      <div className={classes.padded}>
        <ol>
          <li>
            <div className={classes.bolded}>Istuntoevästeet.</div> Käytämme istuntoevästeitä palvelumme hoitamiseen.
          </li>
          <li>
            <div className={classes.bolded}>Ensisijaiset evästeet.</div> Käytämme etuevästeitä muistamaan asetuksesi ja erilaiset asetukset.</li>
          <li>
            <div className={classes.bolded}>Turvaevästeet.</div> Käytämme turvaevästeitä turvallisuustarkoituksiin.
          </li>
        </ol>
      </div>
      <br />
      <div className={`${classes.privacyTermsMainTitle} font-600`}>
        {'Markkinointirekisteriseloste'}
      </div>
      <br />
      Yhdistetty rekisteriseloste- ja informointiasiakirja
      <br />
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>1. Rekisterinpitäjä</div>
      <br />
      Icareus Oy (Y-tunnus: 1707251-0)
      <br />
      <br />
      Itälahdenkatu 18A, 00210 Helsinki
      <br />
      <br />
      Sähköposti: <a
        href='mailto:hevosviihde@icareus.com'
      >
        hevosviihde@icareus.com
      </a>

      <br />
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>2. Yhteystiedot henkilötietojen käsittelyä koskevissa asioissa</div>
      <br />
      Icareus Oy (Y-tunnus: 1707251-0)
      <br />
      <br />
      Itälahdenkatu 18A, 00210 Helsinki
      <br />
      <br />
      Sähköposti: <a
        href='mailto:hevosviihde@icareus.com'
      >
        hevosviihde@icareus.com
      </a>
      <br />
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>
        3. Rekisterin nimi
      </div>
      <br />
      Hevosviihde:n tiedotus- ja markkinointirekisteri.
      <br />
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>4. Henkilötietojen käsittelyn laillinen peruste ja tarkoitus</div>
      <br />
      Hevosviihde -verkkopalvelu ja kaikki palvelun kautta katsottu sisältö on tarkoitettu vain asiakkaan kotona tai asiakasyrityksen tiloissa esitettäväksi. Hevosviihde -verkkopalvelutilauksesi aikana myönnämme sinulle rajoitetun, ei-yksinomaisen, ei-siirrettävän lisenssin Hevosviihde -verkkopalvelun käyttämiseen ja Hevosviihde -verkkopalvelun sisällön katseluun. Edellä mainittua rajoitettua lisenssiä lukuun ottamatta mitään oikeuksia, omistuksia tai muita etuja ei siirretä sinulle.
      <br />
      <br />
      Henkilötietolain 8 §:n ja EU:n tietosuoja-asetuksen 2 luvun mukaiset yleiset edellytykset.
      <br />
      <br />
      Henkilötietojen käsittelyn tarkoitus on viestiä Hevosviihde palvelusta ja uusista sisällöistä.
      <br />
      <br />
      Tarvittavien henkilötietojen käsittely on välttämätön edellytys tarkoituksen toteuttamiseksi.
      <br />
      <br />
      Henkilötietojen käsittelyn perusteena on suostumus. Hevosviihde käyttää kerättyjä tietoja eri tarkoituksiin:
      <br />
      <br />

      <div className={classes.padded}>
        <ol>
          <li>
            tarjota ja ylläpitää palvelua
          </li>
          <li>
            Ilmoittaa sinulle palvelumme muutoksista
          </li>
          <li>
            Antaa sinun osallistua palvelumme interaktiivisiin ominaisuuksiin, kun päätät tehdä niin
          </li>
          <li>
            tarjota asiakaspalvelua ja tukea
          </li>
          <li>
            Tarjoamme analyyseja tai arvokasta tietoa palvelun parantamiseksi
          </li>
          <li>
            Palvelun käytön seuraamiseksi
          </li>
          <li>
            Tunnistaa, ehkäistä ja ratkaista teknisiä ongelmia
          </li>
          <li>
            Käytä kaupallisiin tarkoituksiin, mukaan lukien (myynti) kolmannelle osapuolelle
          </li>
        </ol>
      </div>
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>
        5. Rekisterin tietosisältö
      </div>
      <br />
      Markkinointirekisterissä voidaan käsitellä seuraavia tietoja:
      <br />
      <br />

      <div className={classes.padded}>
        <ol>
          <li>
            Nimitiedot
          </li>
          <li>
            Yhteystiedot (sähköposti)
          </li>
          <li>
            Osoitelähde (mitä kautta osoite on tullut rekisteriin)
          </li>
          <li>
            Yhteydenottojen historiatiedot
          </li>
          <li>
            Muut asiakkaan antamat tiedot
          </li>
        </ol>
      </div>
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>
        6. Säännönmukaiset tietolähteet
      </div>
      <br />
      Markkinointirekisterin henkilötietoja kerätään eri kanavien kautta yhteystietonsa Hevosviihde -palvelulle antaneista henkilöistä (esim. uutiskirjeen tilaus, kilpailun/ arvontakortin täyttäminen).
      <br />
      <br />
      Välttämättömien tietojen antaminen on edellä mainituissa markkinoinnin vastaanottamisen edellytys.
      <br />
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>
        7. Tietojen siirto
      </div>
      <br />
      Tietosi, mukaan lukien Henkilötiedot, voidaan siirtää ja ylläpitää tietokoneille, jotka sijaitsevat maakuntasi, maasi tai muun valtion lainkäyttöalueen ulkopuolella, jos tietosuojalait voivat poiketa omasta alueestasi.
      <br />
      <br />
      Suostumuksesi tähän tietosuojakäytäntöön ja sen jälkeen, kun lähetät tällaiset tiedot, edustaa suostumustasi kyseiseen siirtoon.
      <br />
      <br />
      Hevosviihde ryhtyy kaikkiin kohtuullisesti tarvittaviin toimiin varmistaakseen, että tietojasi käsitellään turvallisesti ja tämän tietosuojakäytännön mukaisesti, eikä henkilötietojasi siirretä organisaatioon tai maahan, ellei käytössä ole riittäviä valvontatoimia, mukaan lukien turvallisuus tietojasi ja muita henkilökohtaisia ​​tietojasi.
      <br />
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>
        8. Tietojen luovuttaminen
      </div>
      <br />
      Hevosviihde voi luovuttaa henkilötietosi vilpittömässä mielessä, kun tällainen toiminta on tarpeen:
      <br />
      <br />

      <div className={classes.padded}>
        <ol>
          <li>
            Lakisääteisen velvoitteen noudattaminen
          </li>
          <li>
            Suojata ja puolustaa Hevosviihde -palvelun oikeuksia tai omaisuutta
          </li>
          <li>
            Estää tai tutkia Palveluun liittyviä mahdollisia väärinkäytöksiä
          </li>
          <li>
            Palvelun käyttäjien tai yleisön henkilökohtaisen turvallisuuden suojaamiseksi
          </li>
          <li>
            Suojautumista oikeudelliselta vastuulta
          </li>
        </ol>
      </div>

      <br />
      Rekisteröityjen henkilötietoja voidaan käsitellä myös Hevosviihde -palvelun markkinoinnin ja myynnin toteuttamiseen osallistuvilla yhteistyökumppaneilla (esim. postitusfirma) tai mahdollista asiakas- tai mielipidetutkimusta varten. Hevosviihde -palvelulla on asianmukaiset sopimukset tällaisten kolmansien tahojen kanssa henkilötietojen käsittelyä varten
      <br />
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>
        9. Tietojen turvallisuus ja rekisterin suojauksen periaatteet
      </div>
      <br />
      Markkinointirekisteriä ylläpidetään sähköisessä muodossa Icareus Suite -järjestelmässä sekä pieniltä osin verkkolevyllä Excel-tiedostoina. Järjestelmien tietokannat ja ohjelmistot ovat palveluntuottajan palvelimilla Internetissä, johon työasemilta on käyttäjä- ja salasanasuojauksin suojattu käyttöliittymä.
      <br />
      <br />
      Rekisterin käyttö edellyttää henkilökohtaista käyttäjätunnusta ja salasanaa, jonka järjestelmä pakottaa vaihtamaan määräajoin. Käyttöoikeus päättyy henkilön siirtyessä pois niistä tehtävistä, joita varten hänelle on myönnetty käyttöoikeus. Samalla lukitaan käyttäjätunnus.
      <br />
      <br />
      Henkilötietoja käsitellään luottamuksellisesti. Rekisterinpitäjä on järjestänyt tiloihinsa kulunvalvonnan.
      <br />
      <br />
      Tietojesi turvallisuus on meille tärkeää, mutta on muistettava, että mikään Internetin kautta lähetettävä menetelmä tai sähköinen tallennustapa ei ole 100% turvallista. Vaikka pyrimme käyttämään kaupallisesti hyväksyttäviä keinoja henkilötietojesi suojaamiseen, emme voi taata niiden absoluuttista turvallisuutta.
      <br />
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>10. Tarkastusoikeus ja tietojen korjaaminen</div>
      <br />
      Rekisteröidyllä on oikeus tarkistaa Hevosviihteen rekisterissä olevat itseään koskevat tiedot ja pyytää virheellisten tietojen korjaamista. Rekisteröity voi esittää tarkastusoikeutta koskevan pyynnön henkilökohtaisesti tai kirjallisesti. Tarkastusoikeus toteutetaan viikon kuluessa pyynnön esittämisestä.
      <br />
      <br />
      Rekisteriin ei sisälly tietoja, joiden osalta tarkastusoikeutta ei voida toteuttaa. Rekisteröity voi esittää tiedon korjaamista milloin tahansa rekisteriasioista vastaavalle henkilölle.
      <br />
      <br />
      Tietoja voidaan oikaista tai täydentää rekisterinpitäjän toimesta tai rekisteröidyn vaatimuksesta.
      <br />
      <br />
      Rekisteröidyllä on oikeus vaatia tietojen käsittelyn rajoittamista ja vastustaa tietojen käsittelyä.
      <br />
      <br />
      Hevosviihteen rekisterissä olevalla henkilöllä on oikeus kieltää antamiensa tietojen käytön suoramarkkinointi- tms. tarkoitukseen, muuttaa niiden julkaisemista koskevaa suostumustaan sekä muutoinkin turvautua henkilötietolaissa turvattuihin oikeuksiinsa. Mikäli henkilö ei toivo Hevosviihteeltä lainkaan postia tai yhteydenottoja, hän voi kieltää sen ottamalla kohdassa 2 mainittuun osoitteeseen.
      <br />
      <br />
      Hevosviihde poistaa tarpeettomat henkilötiedot rekisteristä rekisteröidyn pyynnöstä.
      <br />
      <br />
      Rekisteröidyllä on oikeus tehdä valitus tietosuojaviranomaiselle.
      <br />
      <br />
      <div className={`${classes.privacyTermsTitle} font-400`}>11. Tietojen säilyttäminen</div>
      <br />
      Tietoja säilytetään toistaiseksi, niin kauan kuin ne ovat tarpeellisia. Tarpeettomaksi muuttuneet tiedot ja tiedot, joiden käsittelylle ei enää muutoin ole perustetta, hävitetään tietoturvallisesti.
      <br />
      <br />
      <div className={`${classes.privacyTermsMainTitle} font-600`}>
        {'Palveluntarjoajat'}
      </div>
      <br />
      Voimme palkata kolmannen osapuolen yrityksiä ja yksityishenkilöitä palvelumme helpottamiseksi ("Palveluntarjoajat"), palvelun tarjoamiseksi puolestamme, palveluun liittyvien palvelujen suorittamiseksi tai auttamiseksi meitä palvelumme käytön analysoinnissa.
      <br />
      <br />
      Näillä kolmansilla osapuolilla on pääsy henkilötietoihisi vain näiden tehtävien suorittamiseksi puolestamme ja ne ovat velvollisia olemaan luovuttamatta tai käyttämättä niitä mihinkään muuhun käyttötarkoitukseen.
      <br />
      <br />
      <div className={`${classes.privacyTermsMainTitle} font-600`}>
        {'Analytiikka'}
      </div>
      <br />
      Hevosviihteessä käytettävän videoalustan, Icareus Suite palvelun analytiikka työkalujen lisäksi, voimme käyttää ulkopuolisia palveluntarjoajia seuraamaan ja analysoimaan palvelumme käyttöä.
      <br />
      <br />

      <div className={classes.padded}>
        <ol>
          <li>
            Google Analytics
            <br />
            <br />
            Google Analytics on Googlen tarjoama verkkoanalyysipalvelu, joka seuraa ja raportoi verkkosivustojen liikennettä. Google käyttää kerättyjä tietoja seuraamaan ja seuraamaan palvelumme käyttöä. Nämä tiedot jaetaan muiden Google-palveluiden kanssa. Google voi käyttää kerättyjä tietoja kontekstualisoida ja mukauttaa oman mainosverkoston mainoksia.
            <br />
            <br />
            Voit kieltäytyä tietyistä Google Analytics -ominaisuuksista mobiililaitteiden asetusten kautta, kuten laitteen mainosasetukset tai seuraamalla Googlen tietosuojakäytännössä annettuja ohjeita: <a
              target='_blank'
              rel="noopener noreferrer"
              href='https://policies.google.com/privacy?hl=fi'
            >
              https://policies.google.com/privacy?hl=fi
            </a>
            <br />
            <br />
            Lisätietoja Googlen tietosuojakäytännöistä on Googlen tietosuoja- ja käyttöehdot -sivulla: <a
              target='_blank'
              rel="noopener noreferrer"
              href='https://policies.google.com/privacy?hl=fi'
            >
              https://policies.google.com/privacy?hl=fi
            </a>
          </li>
        </ol>
      </div>
      <br />
      <div className={`${classes.privacyTermsMainTitle} font-600`}>
        {'Linkit muille sivustoille'}
      </div>
      <br />
      Palvelumme voi sisältää linkkejä muille sivustoille, joita emme hallinnoi. Jos napsautat kolmannen osapuolen linkkiä, sinut ohjataan kyseisen kolmannen osapuolen sivustolle. Suosittelemme, että luet jokaisen vierailemasi sivuston tietosuojakäytännön.
      <br />
      <br />
      Meillä ei ole valvontaa emmekä ota vastuuta minkään kolmannen osapuolen sivustojen tai palveluiden sisällöstä, tietosuojakäytännöistä tai käytännöistä.
      <br />
      <br />
      <div className={`${classes.privacyTermsMainTitle} font-600`}>
        {'Muutokset tähän tietosuojakäytäntöön'}
      </div>
      <br />
      Voimme päivittää tietosuojakäytäntömme ajoittain. Ilmoitamme kaikista muutoksista julkaisemalla uuden tietosuojakäytännön tälle sivulle.
      <br />
      <br />
      Ilmoitamme sinulle sähköpostitse ja / tai näkyvässä ilmoituksessa Palvelumme, ennen muutoksen voimaantuloa, ja päivitämme "voimaantulopäivän" tämän tietosuojakäytännön yläosassa.
      <br />
      <br />
      Suosittelemme tarkistamaan tämän tietosuojakäytännön säännöllisesti muutosten varalta. Tämän tietosuojakäytännön muutokset ovat voimassa, kun ne julkaistaan ​​tälle sivulle.
      <br />
      <br />
      <div className={`${classes.privacyTermsMainTitle} font-600`}>
        {'Yhteydenotot'}
      </div>
      <br />
      Jos sinulla on kysyttävää tästä tietosuojakäytännöstä, ota meihin yhteyttä:
      <br />
      <br />
      • sähköpostitse: <a
        href='mailto:hevosviihde@icareus.com'
      >
        hevosviihde@icareus.com
      </a>
      <br />
      <br />
      • Käymällä tällä sivulla verkkosivustollamme: <a
        target='_blank'
        rel="noopener noreferrer"
        href='https://www.hevosviihde.fi'
      >
        www.hevosviihde.fi
      </a>
    </div>
  );
};

export default PrivacyTerms;
