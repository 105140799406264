import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as classes from './ProductDetails.module.css';
import Carousel from 'react-multi-carousel';
import { getImageByKey } from "../../../scripts/getImageByKey";

const ProductDetails = (props) => {

    const { t } = useTranslation();
 
    const history = useHistory();

    const images = [getImageByKey("dune1"), getImageByKey("dune2"), getImageByKey("dune3"), getImageByKey("dune4"), getImageByKey("dune5"), getImageByKey("dune6"), getImageByKey("dune7"), getImageByKey("dune8")];

    const slickSettings = {
        dots: true,
        infinite: true,
        axis: "horizontal",
        lazyLoad: false,
        autoPlaySpeed: 500,
        partialVisible: false,
        autoPlay: false,
        responsive: {
            s1: {
                breakpoint: {
                    max: 4000,
                    min: 1441
                },
                items: 1,
                partialVisibilityGutter: 0,
                slidesToSlide: 1
            },
            s2: {
                breakpoint: {
                    max: 1440,
                    min: 1200
                },
                items: 1,
                partialVisibilityGutter: 0,
                slidesToSlide: 1
            },
            s3: {
                breakpoint: {
                    max: 1199,
                    min: 1020
                },
                items: 1,
                partialVisibilityGutter: 0,
                slidesToSlide: 1
            },
            s4: {
                breakpoint: {
                    max: 1019,
                    min: 950
                },
                items: 1,
                partialVisibilityGutter: 0,
                slidesToSlide: 1
            },
            s5: {
                breakpoint: {
                    max: 949,
                    min: 861
                },
                items: 1,
                partialVisibilityGutter: 0,
                slidesToSlide: 1
            },
            s6: {
                breakpoint: {
                    max: 860,
                    min: 550
                },
                items: 1,
                partialVisibilityGutter: 0,
                slidesToSlide: 1
            },
            s7: {
                breakpoint: {
                    max: 549,
                    min: 0
                },
                items: 1,
                partialVisibilityGutter: 0,
                slidesToSlide: 1
            }
        }
    }

    return (
        <div className={classes.ProductDetailsContainer}>
            <div className={classes.ProductDetailsLeft}>
                <Carousel {...slickSettings}>
                    {images.map((item, i) => {
                        return (
                            <img className={classes.image} src={item} alt='' key={i} />
                        )
                    })}
                </Carousel>
            </div>
            <div className={classes.ProductDetailsRight}>
                <div className={`${classes.title} font-600`}>
                    {"Hevosviihde DUNE-HD Digiboxi"}
                </div>
                <div className={`${classes.priceContainer} font-600`}>
                    <div className={classes.price}>
                        {"139€"}
                    </div>
                    <div className={`${classes.vat} font-400`}>ALV 24%</div>
                </div>

                <button
                className={classes.buyButton}
                onClick={() => history.push(`/${props.routes.packages}/asset/367917`)}
                >
                    {t("product.buy")}
                    </button>

                <div className={`${classes.productDescription} font-200`}>
                    Hevosviihde digiboxi on luotettava teollisuuskäyttöön suunniteltu digi-boxi, joka voi olla päällä pitkiäkin aikoja.
                    <br />
                    <br />
                    Laite kytketään Internetiin luotettavasti Ethernet johdolla ja televisoon HDMI -johdolla mikä tarvittaessa mahdollistaa myös HD -tasoiset ravilähetykset.
                    <br />
                    <br />
                    Liitännät:
                    <br />
                    <br />
                    - 1 x HDMI
                    <br />
                    - 1 x Ethernet
                    <br />
                    - 3,5mm kuulokeliitin
                    <br />
                    - Optical out
                    <br />
                    <br />
                    Laitteen mukana toimitetaan:
                    <br />
                    <br />
                    - Kaukosäädin
                    <br />
                    - Virtajohto
                    <br />
                    <br />
                    Lisätiedot:
                    <br />
                    <br />
                    Takuu 1 vuotta
                </div>

                {/*
                <div className={classes.productSpecs}>
                    {"> IR-vastaanotin"}
                    {"> HD-laatu"}
                </div>
                */}

            </div>
        </div >
    );
}

export default ProductDetails;