import { useTranslation } from "react-i18next";
import { convertCurrency } from "../../../scripts/utils";
import * as classes from "./PackageComponent.module.css";

//shows single package information. Doesn't handle ticket packages or f-liiga single event purchase packages yet.
const PackageComponent = (props) => {
  const { pkg, buyButtonAction, userPackages } = props;

  const { t } = useTranslation();

  return (
    <div className={classes.packageComponent} key={pkg.id}>
      <div className={classes.packageTitle}>{pkg.name}</div>
      <div className={classes.packagePrice}>
        {pkg.price} {convertCurrency(pkg.currency)}
      </div>
      <div
        className={classes.packageDescription}
        dangerouslySetInnerHTML={{ __html: pkg.description }}
      />
      <div className={classes.packageBuyButtonContainer}>
        {userPackages.findIndex(
          (userPkg) =>
            userPkg.sku === pkg.sku &&
            userPkg.validFrom < Date.now() &&
            userPkg.validTo > Date.now()
        ) !== -1 ? (
          <div
            className={classes.packageBoughtButton}
            id={pkg.id}
            name={pkg.id}
          >
            <div className={classes.packageBoughtButtonText}>{t("packages.Bought")}</div>
          </div>
        ) : (
          <div
            className={classes.packageBuyButton}
            id={pkg.id}
            name={pkg.id}
            onClick={() => buyButtonAction(pkg)}
          >
            <div className={classes.packageBuyButtonText}>{t("packages.Order")}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PackageComponent;
