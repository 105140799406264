//import { useHistory } from "react-router-dom";
//import { useTranslation } from "react-i18next";
import * as classes from './TotoDetails.module.css';

const TotoDetails = (props) => {
/*
    const { t } = useTranslation();

    const history = useHistory();
    */

    return (
        <div className={classes.TotoDetailsContainer}>
            <div className={`${classes.title} font-600`}>Hevosurheilua suorana</div>
            <div className={`${classes.text} font-400`}>
            Hevosviihde-tilauksella pääset seuraamaan TotoTV HD- tai TotoTV-kanavia, jotka ovat suunnattu hevosurheilun ystäville. Voit katsoa useita ravitapahtumia eri kanavilta television kautta. Palvelu sisältää sekä TotoTV:n pääkanavan lähetykset että TotoTV:n ratalähetykset. Vuosittain yhteensä yli 2 000 suoraa ravi- ja laukkalähetystä. Kotimaisen raviurheilun lisäksi voit seurata kansainvälisiä huippulähtöjä ja -kisoja ympäri maailman.
            </div>

            <div className={`${classes.title} font-600`}>Hevosviihde tuo huipputapahtumat televisioosi</div>
            <div className={`${classes.text} font-400`}>
            Hevosviihteen ansiosta raveja voi katsoa millä tahansa televisiolla, vuosittain yli 2000 suoraa lähetystä. Kytke Hevosviihde-boxi televisioosi helposti HDMI-kaapelilla ja olet valmis. 
            </div>
        </div >
    );
}

export default TotoDetails;