import { useMyContext } from "../../../contexts/StateHolder";
//import { useState } from "react";
import { useTranslation } from "react-i18next";
import { convertDate } from "../../../scripts/utils";
//import { changePassword, updateUser } from "../../scripts/dataHandlers";
import ProfileHeader from "../ProfileHeader";
import * as classes from "../../../Shared/Input/Input.module.css";
import * as classes2 from "./CurrentSubscription.module.css";
import { cancelSVODSubscription } from "../../../scripts/dataHandlers";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const CurrentSubscription = (props) => {
    // Bring stateholders from context
    const { user, organizationId } = useMyContext();
    const history = useHistory();

    // Setup translate function
    const { t } = useTranslation();

    const subIndex = user.buyerProducts?.findIndex((product) => product.name.includes("Hevosviihde TotoTV"));
    const assetIndex = user.buyerProducts?.findIndex((product) => product.name === "Hevosviihde boxi");

    const onCancelSubscripion = async (item) => {
        console.log("item", item);

        const res = await cancelSVODSubscription(organizationId, item?.buyerProductId, user?.userToken);
        console.log("res", res);

        if (res?.data?.status === "ok") {
            toast.success(t("subscriptions.subscriptionCancelled"));
            setTimeout(() => {
                history.go(0); // REFRESHING THE PAGE
            }, 1000);
        } else {
            toast.error(res?.data?.message || t("shared.errorGeneric"));
        }
    };

    return user?.buyerProducts?.length > 0 ? (
        <div className={classes.formProfilePrimary}>
            <div className={classes.formSecondary}>
                <div className={classes.formBlog}>
                    <ProfileHeader showSubscriptions={props.showSubscriptions} />
                    {subIndex !== -1 ? (
                        <>
                            <div className={classes2.title}>{`${t("subscriptions.subTitle")}:`}</div>
                            <div className={classes2.item}>
                                <div className={`${classes2.itemName} font-400`}>{user.buyerProducts[subIndex].name}</div>
                                <div className={classes2.inner}>
                                    <div className={classes2.subTitle}>{`${t("subscriptions.activeTill")}:`}</div>
                                    <div className={classes2.dateRow}>
                                        {convertDate(user.buyerProducts[subIndex].validFrom)}
                                        <div className={classes2.separator}>-</div>
                                        {convertDate(user.buyerProducts[subIndex].validTo)}
                                    </div>

                                    {user.buyerProducts?.[subIndex]?.nextPayment > Date.now() && (
                                        <>
                                            <div className={classes2.subTitle}>{`${t("subscriptions.nextPayment")}:`}</div>
                                            {convertDate(user.buyerProducts[subIndex].nextPaymentDate)}
                                        </>
                                    )}
                                </div>
                                {user.buyerProducts?.[subIndex]?.validTo >= Date.now() && user.buyerProducts?.[subIndex]?.nextPayment > Date.now() && (
                                    <div
                                        style={{
                                            maxWidth: "100%",
                                            padding: "auto",
                                            marginTop: "20px",
                                        }}
                                    >
                                        <button
                                            style={{
                                                maxWidth: "100%",
                                                padding: "6px 40px",
                                                color: "white",
                                                background: "#be3037",
                                            }}
                                            danger
                                            onClick={() => onCancelSubscripion(user.buyerProducts[subIndex])}
                                        >
                                            {t("packages.Cancel")}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : null}
                    {assetIndex !== -1 ? (
                        <>
                            <div className={classes2.title}>{`${t("subscriptions.assetTitle")}:`}</div>
                            <div className={classes2.item}>
                                <div className={`${classes2.itemName} font-400`}>{user.buyerProducts[assetIndex].name}</div>
                                {convertDate(user.buyerProducts[assetIndex].validFrom)}
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    ) : (
        <div className={classes.formProfilePrimary}>
            <div className={classes.formSecondary}>
                <form className={classes.formBlog}>
                    <ProfileHeader showSubscriptions={props.showSubscriptions} />
                    <div className={classes2.placeholder}>{t("subscriptions.placeholder")}</div>
                </form>
            </div>
        </div>
    );
};

export default CurrentSubscription;
