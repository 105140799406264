import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import * as classes from './DownloadInstructions.module.css';
//import { Document, Page } from 'react-pdf';
import dunePDF from '../../../files/dune.pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Link } from 'react-router-dom';
import { FaFileDownload, FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const DownloadInstructions = (props) => {

    const { t } = useTranslation();

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <div className={classes.maxContainer}>
        <div className={classes.DownloadInstructionsContainer}>
            
                <Document
                    file={dunePDF}
                    onLoadSuccess={onDocumentLoadSuccess}
                    renderAnnotationLayer={false}
                >
                    <div className={`${classes.pageControls} ${classes.before}`}>

                        <div className={classes.downloadRowFiller}>
                            {t("instructions.download")}
                            <Link to={dunePDF} target="_blank" download><FaFileDownload className={classes.FaIcon} /></Link>
                        </div>
                        <div className={classes.pageArrows}>

                            <button
                                className={classes.PreviousButton}
                                onClick={() => pageNumber > 1 ? setPageNumber(pageNumber - 1) : null}
                            >
                                <FaArrowLeft className={classes.FaArrow} />
                            </button>

                            <div className={classes.pageNumbers}>
                                <span>
                                    <p>{t('instructions.page')} {pageNumber} / {numPages}</p>
                                </span>
                            </div>

                            <button
                                className={classes.NextButton}
                                onClick={() => pageNumber < numPages ? setPageNumber(pageNumber + 1) : null}
                            >
                                <FaArrowRight className={classes.FaArrow} />
                            </button>
                        </div>
                        <div className={classes.downloadRow}>
                            {t("instructions.download")}
                            <Link to={dunePDF} target="_blank" download><FaFileDownload className={classes.FaIcon} /></Link>
                        </div>

                    </div>

                    <Page pageNumber={pageNumber} className={classes.page} />

                    <div className={`${classes.pageControls} ${classes.after}`}>
                        <button
                            className={classes.PreviousButton}
                            onClick={() => pageNumber > 1 ? setPageNumber(pageNumber - 1) : null}
                        >
                            <FaArrowLeft className={classes.FaArrow} />
                        </button>

                        <div className={classes.pageNumbers}>
                            <span>
                                <p>{t('instructions.page')} {pageNumber} / {numPages}</p>
                            </span>
                        </div>

                        <button
                            className={`${classes.NextButton} ${classes.after}`}
                            onClick={() => pageNumber < numPages ? setPageNumber(pageNumber + 1) : null}
                        >
                            <FaArrowRight className={classes.FaArrow} />
                        </button>
                    </div>
                </Document>
            </div>
        </div >
    );
}

export default DownloadInstructions;