import { useHistory } from 'react-router-dom';
import { getImageByKey } from '../../scripts/getImageByKey';
import { useTranslation } from 'react-i18next';
import * as classes from './Footer.module.css';
import { NavLink } from 'react-router-dom';

const BottomBar = (props) => {
  const history = useHistory();

  const { t } = useTranslation();

  return (

    <div className={classes.Footer_primary}>
      <div className={`${classes.Footer} font-200`}>
        <div className={classes.Footer_SiteLogo}>
          <img
            className='navBarSiteLogo'
            src={getImageByKey('siteLogo2')}
            title={t('Back to Home')}
            alt='SiteName'
            onClick={() => history.push('/')}
            style={props?.styles?.navBar}
          />
        </div>
        <div className={classes.Footer_OrganizationInfo}>
          <div className={classes.Footer_Info_OrganizationName}>
            <p style={{ fontWeight: "bold" }}>Icareus Oy</p>
          </div>
          <div className={classes.Footer_Inforow}> Itälahdenkatu 18 A</div>
          <div className={classes.Footer_Inforow}> FI-00210 Helsinki</div>
          <div className={classes.Footer_Inforow}>
            {' '}
            <a
              href='mailto:hevosviihde@icareus.com'
            >
              hevosviihde@icareus.com
            </a>
          </div>
        </div>
        <div className={classes.tosAndPrivacyTermsContainer}>
          <NavLink to='/privacyTerms' exact activeClassName='no-class'>
            Tietosuojaseloste
          </NavLink>
          <NavLink to='/tos' exact activeClassName='no-class'>
            Palveluehdot
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default BottomBar;