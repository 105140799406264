import * as classes from './FAQ.module.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const FAQ = (props) => {
  const { t } = useTranslation();
  const currentURL = window.location.href;
  console.log(currentURL);
  return (
    <div className={`${classes.faqContainer} font-300`}>
      <div className={`${classes.faqTitle} font-600`}>{t('faq.title')}:</div>

      <div className={classes.item}>
        <div className={`${classes.faqTitle} font-400`}>
          Miten rekisteröin Hevosviihde boxini?
        </div>
        <br />
        Sinun ei tarvitse ostaa uutta Hevosviihde boxia vaan voit jatkaa
        nykyisellä Hevosviihde boxillasi. Palvelun jatkumisen takaamiseksi:
        <br />
        1) Tilaa Hevosviihde TotoTV täältä (
        <NavLink to={`/${props.buyRoute}`} exact activeClassName='no-class'>
          Tilaa
        </NavLink>
        )
        <br />
        2) Rekisteröi olemassa oleva Hevosviihde boxisi. Rekisteröinti tapahtuu
        helpoiten ottamalla kuva laitteesta löytyvästä tarrasta, jossa on MAC
        -osoite ja sarjanumero. Lähetä kuva osoitteeseen{' '}
        <a href='mailto:hevosviihde@icareus.com'>
          hevosviihde@icareus.com
        </a>{' '}
        sähköpostista, jolla olet rekisteröitynyt Hevosviihdepalveluun.
      </div>

      <div className={classes.item}>
        <div className={`${classes.faqTitle} font-400`}>
          Mikä on Hevosviihde?
        </div>
        <br />
        Hevosviihde on Icareuksen tuottama palvelu, jonka kautta on saatavilla
        kaikki TotoTV sisältö. Hevosviihdepalvelu tilataan kuukausimaksulla ja
        on jatkuva tilaus. Hevosviihdettä varten tarvitset myös hevosviihde
        boxin, jonka avulla katsot kaikkia TotoTV lähetyksiä mukavasti
        televisiosi kautta.
      </div>

      <div className={classes.item}>
        <div className={`${classes.faqTitle} font-400`}>
          Mikä on Hevosviihde boxi?
        </div>
        <br />
        Hevosviihde boxi on luotettava teollisuuskäyttöön suunniteltu digi-boxi.
        Laite kytketään Internetiin luotettavasti Ethernet johdolla ja
        televisoon HDMI -johdolla mikä tarvittaessa mahdollistaa myös HD
        -tasoiset ravilähetykset.
      </div>

      <div className={classes.item}>
        <div className={`${classes.faqTitle} font-400`}>
          Tarvitsenko Internet yhteyden hevosviihdettä varten?
        </div>
        <br />
        Kyllä tarvitset. Suosittelemme vähintään 2Mbit/s yhteyttä, jotta myös HD
        lähetykset näkyvät sujuvasti.
      </div>

      <div className={classes.item}>
        <div className={`${classes.faqTitle} font-400`}>
          Miten kytken Hevosviihde boxini televisioon?
        </div>
        <br />
        Boxi kytketään televisioon HDMI kaapelilla.
      </div>

      <div className={classes.item}>
        <div className={`${classes.faqTitle} font-400`}>
          Maksuni epäonnistui, mitä seuraavaksi?
        </div>
        <br />
        Ensimmäiseksi on hyvä tarkistaa, että käyttämällänne kortilla saa tehdä
        ostoksia Internetissä. Ostotapahtuma voi keskeytyä useasta muustakin
        syystä: Olet voinut esimerkiksi siirtyä liian aikaisin pois sivulta,
        jolloin maksu on keskeytynyt tai et ole antanut palvelimen ohjata sinua
        takaisin palveluntarjoajan sivulle. Syynä saattaa olla myös riittämätön
        tilikate.
        <br />
        <br />
        Mikäli näin on eikä osto millään onnistu niin voit olla yhteydessä
        tukeemme{' '}
        <a href='mailto:production@icareus.com'>(production@icareus.com)</a> ja
        lähettää heille seuraavat tiedot:
        <br />
        <br />
        - ostotapahtuman aika
        <br />
        - mitä olet koittanut ostaa
        <br />- käyttäjätunnus/sähköposti, jolla osto on koitettu tehdä
        <br />
        <br />
          Mikäli korttimaksu tai verkkopankkimaksu eivät edelleen onnistu, niin
          vaihtoehtoja alla.
        <br />
        <br />
        <p style={{ fontWeight: '700' }}>
        Minulla ei ole luottokorttia jolla voisin maksaa / haluan yrityslaskutuksen
        </p>
        <br />
        Vaihtoehtoisesti Hevosviihde tarjoaa mahdollisuuden myös
        yrityslaskutukseen. Voit valita maksuvaihtoehdoksi joko 6kk tai 12kk
        -kertatilaus paketin, jolloin voit maksaa 6kk/12kk tilauksen etukäteen.
        <br />
        <br />
        Yrityslaskutuksen hoitaa Fellow Yrityslaskutus. Voit valita
        laskutusvaihtoehdon VismaPay maksuvaihtoehdoista.
        <br />
        <br />
        Muista, että kertatilauspaketti päättyy automaattisesti kauden päätyttyä
        ja joudut tilaamaan palvelun uudestaan jatkaaksesi palvelua.
        <br />
        <br />
        Mikäli kysyttävää, niin ole yhteydessä asiakaspalveluumme 
        <a href='mailto:sales@icareus.com.'> sales@icareus.com.</a>
        <br />
        <br />
      </div>

      <div className={classes.item}>
        <div className={`${classes.faqTitle} font-400`}>
          Miten irtisanon Hevosviihde -tilaukseni?
        </div>
        <br />
        Hevosviihdetilaus irtisanotaan omalta profiilisivultasi, jonne pääset
        kirjautumalla hevosviihde -sivuille.
      </div>

      <div className={classes.item}>
        <div className={`${classes.faqTitle} font-400`}>
          Pitääkö Hevosviihde boxi palauttaa tilauksen loputtua?
        </div>
        <br />
        Ei pidä, ostettuasi boxin sen on sinun ja sitä koskee normaali vuoden
        takuumme.
      </div>
    </div>
  );
};

export default FAQ;
