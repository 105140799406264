import * as classes from './TOS.module.css';
import { getImageByKey } from '../../scripts/getImageByKey';
//import { useTranslation } from 'react-i18next';

const TOS = (props) => {
  //const { t } = useTranslation();

  return (
    <div
      className={`${classes.tosContainer} font-300`}
    >
      <div className={`${classes.tosMainTitle} font-600`}>
        {'Hevosviihde -palvelun ehdot'}
      </div>
      <br />
      Toimitusehdot ovat voimassa 1.12.2021 alkaen.
      <br />
      <br />
      Tämä on Hevosviihde online videosisällön tilausvideopalvelu ja verkkokauppa (Hevosviihde), jonka avulla tilaajat pääsevät käyttämään Hevosviihteen suoria ravilähetyksiä sekä mahdollisia muita sisältöjä ("Hevosviihde: n verkkopalvelun sisältö"), joita striimataan Internetin kautta kytkettyihin digiboksi päätelaitteisiin ("Hevosviihde verkkopalvelulaitteet").
      <br />
      <br />
      Hevosviihde -verkkopalvelun palveluntarjoaja on Icareus Oy (Y-tunnus 1707251-0) jolla on sopimus palvelusta Veikkaus Oy:n kanssa. Nämä palveluehdot säätelevät palvelumme käyttöä. Näissä palveluehdoissa käytettynä "Hevosviihde -palvelu", "palvelumme" tai "palvelu" tarkoittaa Icareus Oy: n tarjoamaa Hevosviihde -verkkopalvelua sisältömme löytämiseen ja katseluun, mukaan lukien kaikki ominaisuudet ja toiminnot, verkkosivusto ja käyttöliittymät sekä kaikki palveluumme liittyvät sisällöt ja ohjelmistot.
      <br />
      <br />
      Palvelu ja siellä näkyvät live-materiaalit, kuvat, tekstit ja logot ovat Icareus Oy:n tai Veikkaus Oy omaisuutta, ja niitä ei saa ilman edellä mainittujen tahojen yksilöityä lupaa tallentaa, jakaa, levittää tai näyttää yksityisesti ja/ tai julkisesti.
      <br />
      <br />
      Yhteystiedot:
      <br />

      Sähköposti: <a
        href='mailto:hevosviihde@icareus.com'
      >
        hevosviihde@icareus.com
      </a>
      <br />
      Osoite: Itälahdenkatu 18 A, 00210 Helsinki, Finland
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Tilaus sekä tilausvahvistus</div>
      <br />
      Asiakas rekisteröityy Hevosviihde videopalvelu -järjestelmään ja luo sinne henkilökohtaisen profiilin. Tilattava sisältöpaketti ja ostettava Hevosviihteen verkkopalvelulaite valitaan ostosivuilla. Tilaus lähetetään maksamalla valittu sisältö verkkokaupan maksutoiminnossa. Tehdessäsi tilauksen hyväksyt nämä toimitusehdot ja palvelun hinnat. Mikäli tilaushetkellä annetaan sähköpostiosoite, tilauksesta lähetetään tilausvahvistus sähköpostitse. Tilausvahvistuksesta ilmenevät tilatut tuotteet sekä hinta.
      <br />
      <br />
      Jos asiakkaalle ei syystä tai toisesta tule tilausvahvistusta, tilaus ei ole välttämättä onnistunut. Voit tarkistaa tilauksesi tilanteen Hevosviihde -sivun Profiilisi -alta, kun olet kirjautunut palveluun. Mikäli profiilissasi ei näy tilausta, voit ottaa yhteyttä asiakaspalveluumme.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Sopimuksen syntyminen sekä maksaminen</div>
      <br />
      Sopimus syntyy, kun asiakas on kirjautunut järjestelmään ja maksanut valitsemansa paketin. Kauppa syntyy verkkokaupan asiakkaan ja Hevosviihde palveluntarjoajan välille. Verkkokaupan vastuulla ovat kaikki kauppaan liittyvät velvoitteet.
      <br />
      <br />
      Verkkokaupan maksuvälittäjänä toimii Visma Pay (Paybyway Oy, y-tunnus 2486559-4, https://www.vismapay.com/), joka on rekisteröity Finanssivalvonnan ylläpitämään maksulaitosrekisteriin. Maksamiseen siirrytään Visma Payn verkkopalvelun kautta ja tiliotteella ja laskulla maksun saajana näkyy Visma Pay tai Paybyway Oy. Visma Pay välittää maksut kauppiaalle. Maksaminen on turvallista, sillä kaikki maksutapahtumaa koskevat tiedot välitetään salattua yhteyttä käyttäen niin ettei kukaan ulkopuolinen taho näe maksutapahtuman tietoja.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Maksutavat</div>
      <br />
      Maksutapa valitaan tilauksen tekemisen yhteydessä.
      <br />
      <br />
      Visma Pay -palvelun kautta voit maksaa verkkopankkitunnuksilla, lompakolla, maksukorteilla (credit/ debit), laskulla tai osamaksulla. Käytettävissä ovat seuraavat maksutavat: Osuuspankki, Nordea, Danske Bank, Oma Säästöpankki, Säästöpankki, Aktia, Paikallisosuuspankit, S-Pankki, Handelsbanken, Ålandsbanken, MobilePay, Masterpass, Visa-, Visa Debit-, Visa Electron-, MasterCard-, Debit MasterCard- ja American Express -kortit. Lisäksi maksutavaksi käy erikseen lunastettu Arvoseteli (voucher).
      <br />
      <br />
      MobilePay: Voit maksaa MobilePay-lompakollasi, mikäli olet sallinut verkkokaupoissa maksamisen sovelluksen asetuksista. MobilePay-lompakolla suoritetut maksut veloitetaan lompakkoon liitetyltä maksukortilta. Mikäli maksun veloittaminen maksukortilta epäonnistuu, MobilePay-lompakolla maksaminen ei ole mahdollista verkkokaupassa.
      <br />
      <br />
      <img src={getImageByKey("vismaPay")} alt="" />
      <br />
      <br />
      Visma Pay -maksupalvelun yhteystiedot
      <br />
      Visma Pay, Paybyway Oy (Y-tunnus 2486559-4)
      <br />
      Sähköposti: helpdesk@vismapay.com
      <br />
      Puhelin: 09 3154 2037 (arkisin klo 9-16)
      <br />
      Postiosoite: Laserkatu 6, 53850 Lappeenranta
      <br />
      <br />
      Kaikki maksutavat ovat erittäin turvallisia. Verkkomaksut ja luottokorttimaksut maksetaan pankkien tai Visma Pay:n sivujen kautta. Hevosviihde -palvelu ja Icareus Oy eivät tallenna tai saa tietoonsa asiakkaiden tilitietoja. Luottokorttimaksujen tiedot tallentavat Visma Pay (Paybyway Oy). Luottokorttimaksujen varmentamiseen käytetään "Verified by Visa" tai "MasterCard SecureCode" -järjestelmiä. Luottokorttiveloitus hyväksytään ja korttia veloitetaan tämän vuoksi tilaushetkellä.
      <br />
      <br />
      Hevosviihde -palvelusta asiakasta veloitetaan automaattisesti luottokortilta kestotilauksen summa kuukausittain.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Ennakko- ja yrityslaskut </div>
      <br />
      Jos yllä mainituista vaihtoehdoista ei löydy mieleistä tai yrityksellä ei ole luottokorttimahdollisuutta, voi asiakas maksaa laskun ennakkolaskuna, joka lähetetään sähköpostiisi. Ennakkolaskutettujen tilausten toimitusajan laskeminen alkaa siitä, kun asiakkaan suoritus näkyy Icareuksen järjestelmässä.
      <br />
      <br />
      Ennakkolaskulla maksetuista tilauksista Veloitamme laskutuslisän 5,00 € per lähetetty lasku. Valitessaan maksutavaksi laskun asiakas vakuuttaa, että hänen taloudellinen tilanteensa tilaushetkellä on sellainen, että hän kykenee ongelmitta suorittamaan laskun summan ja että hänen tiedossaan ei ole sellaista seikkaa, joka saattaisi vaarantaa hänen maksukykynsä. Icareus Oy:llä on oikeus tarkistaa laskulla maksavan asiakkaan luottotiedot.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Arvosetelit sekä voucherit
      </div>
      <br />
      Arvoseteliä voi käyttää palvelussa maksuvälineenä. Arvoseteliä tai voucheria voi käyttää kaikille normaaleille tuotteille ja tilauksille. Arvoseteliä ei voi käyttää mahdollisten lisämaksullisten palvelujen ostamiseen.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Hinnat</div>
      <br />
      Tuotteiden hinnat sisältävät arvonlisäveron.
      <br />
      <br />
      Tuote myydään tilaamishetkellä verkkokaupan sivulla ilmoitettavalla hinnalla. Hevosviihde pidättää oikeuden muuttaa hintoja sekä olla myymättä tuotetta hinnalla, joka on esim. teknisestä tai inhimillisestä syystä päivittynyt verkkokauppaan olennaisesti väärin. Tällaisessa tapauksessa sovelletaan yleisiä sopimusoikeudellisia periaatteita. Näissä tapauksissa Hevosviihde on yhteydessä asiakkaaseen ja kaupan purkaminen on mahdollista.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Tilauksen peruutus</div>
      <br />
      Voit peruuttaa Hevosviihde -verkkopalvelun kestotilauksen milloin tahansa, jonka jälkeen sinulla on edelleen pääsy Hevosviihde -verkkopalveluun nykyisen laskutusjaksosi loppuun asti.
      <br />
      <br />
      Voit peruuttaa tilauksesi menemällä "profiili" -sivulle ja valitse tilauksesi kohdalla ”peruuta” tilaus, jolloin tilauksesi päättyy automaattisesti nykyisen laskutusjaksosi lopussa. "Profiili" -sivun "Oma tilaus" -kohdassa voit myös nähdä, milloin tilauksesi päättyy.
      <br />
      <br />
      Sovellettavan lain sallimassa laajuudessa maksuja ei palauteta, emmekä tarjoa hyvityksiä tai hyvityksiä osittaisten kuukausien tilausjaksoista tai katsomattomasta Hevosviihde -palvelun sisällöstä. Myöskään yksittäisillä päivätilauksilla ei ole palautusoikeutta.
      <br />
      <br />
      Muutokset hinta- ja palvelusuunnitelmiin: Voimme aika ajoin muuttaa sisältöpakettejamme ja palvelumme hintaa; Hintamuutokset tai palvelusuunnitelmien muutokset koskevat kuitenkin aikaisintaan 30 päivää ilmoituksen jälkeen.
      <br />
      <br />
      <div className={`${classes.tosMainTitle} font-600`}>
        {'Hevosviihde verkkopalvelu'}
      </div>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Asiakkaiden oltava täysi-ikäisiä
      </div>
      <br />
      Sinun on oltava vähintään 18-vuotias, jotta sinulla on oikeus tilata Hevosviihde -verkkopalvelu. Alaikäiset voivat käyttää palvelua vain aikuisen valvonnassa.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Esitysoikeudet</div>
      <br />
      Hevosviihde -verkkopalvelu ja kaikki palvelun kautta katsottu sisältö on tarkoitettu vain asiakkaan kotona tai asiakasyrityksen tiloissa esitettäväksi. Hevosviihde -verkkopalvelutilauksesi aikana myönnämme sinulle rajoitetun, ei-yksinomaisen, ei-siirrettävän lisenssin Hevosviihde -verkkopalvelun käyttämiseen ja Hevosviihde -verkkopalvelun sisällön katseluun. Edellä mainittua rajoitettua lisenssiä lukuun ottamatta mitään oikeuksia, omistuksia tai muita etuja ei siirretä sinulle.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Julkinen esittäminen
      </div>
      <br />
      Hevosviihde -palvelua voi käyttää myös julkisissa tiloissa, esimerkiksi ravintoloissa, kioskeissa ja kaupoissa seuraavalla rajoitteella: Hevosviihde -palvelun kautta lähetettävät ravilähetykset sisältävät musiikkia ja siksi Asiakkaan on oltava musiikin julkisen esittämiseksi vaadittava GT-lupa. Luvan voi hankkia GT Musiikkiluvat Oy:ltä, joka on Gramexin ja Teoston yhteisyritys, verkko-osoitteeessa musiikkiluvat.fi.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Muutokset palveluun
      </div>
      <br />
      Hevosviihde pidättää kaikki oikeudet palvelun muutoksiin, mukaan lukien sisältökirjasto, hinnoittelu ja sisältöpaketointi. Lisäksi testaamme jatkuvasti palvelumme eri puolia, kuten verkkosivustoamme, käyttöliittymiä, myynninedistämistoimintoja ja Hevosviihde verkkopalvelusisällön saatavuutta.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Hevosviihde palvelun käyttö
      </div>
      <br />
      Suostut käyttämään Hevosviihde -verkkopalvelua, mukaan lukien kaikki siihen liittyvät ominaisuudet ja toiminnot, kaikkien sovellettavien lakien, sääntöjen ja määräysten tai muiden palvelun tai sen sisällön käyttöä koskevien rajoitusten mukaisesti. Sitoudut olemaan arkistoimatta, jäljentämättä, jakamatta, muuntamatta, näyttämättä, esittelemättä, julkaisematta, lisensoimatta, tarjoamatta myyntiin tai käyttämättä (paitsi näissä käyttöehdoissa nimenomaisesti sallittua) sisältöä ja tietoja, jotka ovat tai ovat peräisin tai Hevosviihde -verkkopalvelun kautta. Suostut myös olemaan: kiertämästä, poistamasta, muuttamasta, deaktivoimasta, heikentämästä tai estämästä mitään Hevosviihde -verkkopalvelun sisältösuojauksia käyttäen mitä tahansa robottia, työkalua tai muuta automaattista tapaa päästäksesi Hevosviihde -verkkopalveluun; takaisinmallintamatta tai purkamatta ohjelmistoja tai muita tuotteita tai prosesseja, joihin pääsee Hevosviihde -verkkopalvelun kautta; lisäämättä mitä tahansa koodia tai tuotetta tai manipuloimatta Hevosviihde -verkkopalvelun sisältöä millään tavalla; tai käyttämättä mitä tahansa tiedonlouhinta-, tiedonkeruu- tai poimintamenetelmää. Lisäksi suostut olemaan lataamatta tai lähettämättä mitään materiaalia, joka on suunniteltu Hevosviihde -verkkopalveluun liittyvän tietokoneohjelmiston tai laitteiston tai tietoliikennelaitteiston toiminnan keskeyttämiseksi, tuhoamiseksi tai rajoittamiseksi, mukaan lukien ohjelmistovirukset tai muut tietokoneet, tiedostot tai ohjelmat. Voimme lopettaa tai rajoittaa palvelumme käyttöä, jos rikot näitä käyttöehtoja tai käytät palvelua laittomasti tai vilpillisesti.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Hevosviihde videosisällönlaatu ja Internet-yhteys
      </div>
      <br />
      Hevosviihde -verkkopalvelun sisällön näytön laatu voi vaihdella laitteittain, ja siihen voivat vaikuttaa monet tekijät, kuten päätelaitteet, Internet -modeemi, sijainti, Internet-yhteyden kautta käytettävissä oleva kaistanleveys ja / tai nopeus. Olet vastuussa kaikista Internet-yhteysmaksuista. Kysy Internet-palveluntarjoajalta tietoja mahdollisista Internet-tiedonsiirtomaksuista. Internet -datakapasiteetti joka kuluu Hevosviihde -verkkopalvelun sisällön katseluun, vaihtelee useiden tekijöiden mukaan, mukaan lukien sijaintisi, käytettävissä oleva kaistanleveys, valitsemasi sisältö ja Hevosviihde -verkkopalveluun käyttämäsi päätelaite.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Force majeure
      </div>
      <br />
      Icareus Oy ei ole vastuussa palvelun toimimattomuudesta, viivästyksestä tai estymisestä, mikäli syy tähän on Icareus Oy:n vaikutusmahdollisuuksien ulkopuolella oleva seikka, kuten tavarantoimitusten katkokset, estävät sääolosuhteet, luonnonmullistus, sota, onnettomuus, epidemia, tuonti- tai vientikielto, viranomaisten päätös, liikenteen tai energian jakelun häiriintyminen tai estyminen, työtaistelutoimenpide tai muu vastaava Icareus Oy:n tai aiemman myyntiportaan toimintaa estävä seikka.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Salasanat ja tilin käyttö</div>
      <br />
      Tilaajalla, joka on luonut Hevosviihde -verkkopalvelutilin ja jonka maksutapaa veloitetaan ("tilin omistaja"), on pääsy Hevosviihde -verkkopalvelutiliin. Tilin haltijan pitää hallita tiliä ja estää ketään pääsemästä tiliin (joka sisältää tietoja tilin katseluhistoriasta), tilinomistajan tulee ylläpitää hallintaa Hevosviihde -verkkopalveluun käytettävistä laitteista, joita käytetään palvelun käyttämiseen. Älä ikinä paljasta tiliin liittyvän maksutavan salasanaa tai yksityiskohtia kenellekään. Olet vastuussa meille antamiesi tiliäsi koskevien tietojen päivittämisestä ja ylläpitämisestä. Voimme lopettaa tilisi tai keskeyttää tilisi toiminta suojellaksemme sinua, Icareusta tai kumppaneitamme henkilöllisyysvarkauksilta tai muulta vilpilliseltä toiminnalta.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Sovellettava lainsäädäntö</div>
      <br />
      Näihin käyttöehtoihin sovelletaan ja niitä tulkitaan Suomen lakien mukaisesti. Nämä ehdot eivät rajoita kuluttajansuojaoikeuksia, joihin sinulla saattaa olla oikeus asuinmaasi pakollisten lakien nojalla. Asiakkaan ja Icareus Oy väliset riitatapaukset ratkaistaan ensisijaisesti neuvottelemalla ja jos sopimukseen ei päästä, niin Helsingin käräjäoikeudessa.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Ei-toivotut materiaalit</div>
      <br />
      Icareus Oy ei hyväksy ei-toivottuja materiaaleja tai ideoita Hevosviihde -verkkopalvelusisällölle, eikä ole vastuussa sisältönsä tai ohjelmiensa samankaltaisuudesta missään mediassa Hevosviihde -verkkopalveluun välitettyihin materiaaleihin tai ideoihin.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Asiakaspalvelu
      </div>
      <br />
      Jos haluat lisätietoja palvelustamme ja sen ominaisuuksista tai jos tarvitset apua, ota yhteyttä asiakaspalveluumme osoitteessa <a
        href='mailto:hevosviihde@icareus.com'
      >
        hevosviihde@icareus.com
      </a>. Jos näiden käyttöehtojen ja asiakastuen tai verkkosivustomme muiden osien välisten tietojen välillä on ristiriitoja, nämä käyttöehdot hallitsevat.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
      Sopimusehdot
      </div>
      <br />
      Jos jokin näiden palveluehtojen kohta tai kohdat katsotaan pätemättömiksi, laittomiksi tai täytäntöönpanokelvottomiksi, jäljellä olevienehtojen pätevyys, laillisuus ja täytäntöönpanokelpoisuus ovat edelleen voimassa.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
      Palveluehtojen muutokset
      </div>
      <br />
      Icareus Oy voi ajoittain muuttaa näitä palveluehtoja. Icareus Oy pidättää oikeuden muuttaa palvelu- ja sopimusehtojaan ilmoituksetta. Pyrimme kuitenkin ilmoittamaan sinulle vähintään 30 päivää ennen kuin uudet käyttöehdot koskevat sinua.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
      Yrityksen vastuu ja vastuun rajoitukset
      </div>
      <br />
      Icareus Oy ei vastaa yrityksen vaikutusmahdollisuuksien ulkopuolella ilmenevistä ongelmista (esim. yksittäisen kuluttajan Internet-operaattorista johtuvista Internetyhteyden pätkimisistä tai laitteiston yhteensopivuusongelmista). 
      <br />
      <br />
      Icareus Oy pidättää oikeuden toimitusehtojen ja hintojen sekä aikataulujen muutoksiin. 
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Sähköinen viestintä</div>
      <br />
      Lähetämme sinulle tiliäsi koskevia tietoja (esim. Maksuvaltuudet, laskut, salasanan tai maksutavan muutokset, vahvistusviestit, ilmoitukset) vain sähköisessä muodossa, esimerkiksi sähköpostitse rekisteröinnin yhteydessä annettuun sähköpostiosoitteeseesi.
      <br />
      <br />
      Viimeksi päivitetty: 1. joulukuuta 2021.
    </div>
  );
};

export default TOS;
