import { useMyContext } from '../../contexts/StateHolder';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

// Renders contentRatings of chosen item
const Login = (props) => {
  // Bring stateholders from context
  const { user, setUser, closeHamMenu } = useMyContext();

  const { t } = useTranslation();

  const [cookies, removeCookie] = useCookies('');

  const logOut = () => {
    // Set userdata by forming new object from old userData, so react understands state change.
    let usr = { ...user };

    // Change user's loggedIn status to false and clear userToken
    //usr.loggedIn = false;
    //usr.userToken = "";
    usr = {};

    // Update user into context values
    setUser(usr);

    // If there's cookie with userData, remove it
    if (cookies?.userData) {
      removeCookie('userData');
    }
  };

  return (
    <div className='loginContainer'>
      {/* <NavLink
        className='profileBTN'
        onClick={() => clickProfile()}
        style={user.loggedIn ? { display: 'flex' } : { display: 'none' }}
        to='/login'
      >
        {t('login.profileButton')}
      </NavLink> */}

      {user.loggedIn ? (
        <>
          <NavLink className='loginBTN' to={`/${props.routes.profile}`}>
            {user.loggedIn
              ? t('login.profileButton')
              : t('login.profileButton')}
          </NavLink>
          <div>{'|'}</div>
          <NavLink
            className='loginBTN'
            onClick={() => {
              logOut();
            }}
            to={`/${props.routes.login}`}
          >
            {user.loggedIn ? t('login.logOutButton') : t('login.loginButton')}
          </NavLink>
        </>
      ) : (
        <NavLink
          className='loginBTN'
          onClick={closeHamMenu}
          to={`/${props.routes.login}`}
        >
          {user.loggedIn ? t('login.logOutButton') : t('login.loginButton')}
        </NavLink>
      )}
    </div>
  );
};

export default Login;
